import React, { useEffect, useContext } from "react"
// import PropTypes from "prop-types";
// import {
//   mediaPropTypes,
//   linkPropTypes,
//   buttonLinkPropTypes,
// } from "@/utils/types";
// import { useLockBodyScroll } from "@/utils/hooks";
import gsap from "gsap"
import { TransitionElementContext } from "../context/transitionElementContext";
import SplitText from "./elements/split-text";
import { isSafariBrowser, isEmbeddedBrowser } from "../utils/isBrowser";
// import { IFrame } from "../utils/IFrame";

const ease = {
  exponentialIn: (t) => {
    return t === 0.0 ? t : Math.pow(2.0, 10.0 * (t - 1.0));
  },
  exponentialOut: (t) => {
    return t === 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
  },
  exponentialInOut: (t) => {
    return t === 0.0 || t === 1.0
      ? t
      : t < 0.5
        ? +0.5 * Math.pow(2.0, (20.0 * t) - 10.0)
        : -0.5 * Math.pow(2.0, 10.0 - (t * 20.0)) + 1.0;
  },
  sineOut: (t) => {
    const HALF_PI = 1.5707963267948966;
    return Math.sin(t * HALF_PI);
  },
  circularInOut: (t) => {
    return t < 0.5
        ? 0.5 * (1.0 - Math.sqrt(1.0 - 4.0 * t * t))
        : 0.5 * (Math.sqrt((3.0 - 2.0 * t) * (2.0 * t - 1.0)) + 1.0);
  },
  cubicIn: (t) => {
    return t * t * t;
  },
  cubicOut: (t) => {
    const f = t - 1.0;
    return f * f * f + 1.0;
  },
  cubicInOut: (t) => {
    return t < 0.5
      ? 4.0 * t * t * t
      : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
  },
  quadraticOut: (t) => {
    return -t * (t - 2.0);
  },
  quarticOut: (t) => {
    return Math.pow(t - 1.0, 3.0) * (1.0 - t) + 1.0;
  },
}



class ShapeOverlays {
  constructor(elm, sectionElem) {
    this.elm = elm;
    this.sectionElem = sectionElem;
    this.path = elm.current.querySelectorAll('path');
    this.numPoints = 2;
    this.duration = 800;
    this.delayPointsArray = [];
    this.delayPointsMax = 300;
    this.delayPerPath = 30;
    this.timeStart = Date.now();
    this.isOpened = true;
    this.isAnimating = false;
  }
  toggle(animateOut) {
    this.path = this.elm.current.querySelectorAll('path');
    this.isAnimating = true;
    const range = 4 * Math.random() + 6;
    for (var i = 0; i < this.numPoints; i++) {
      const radian = i / (this.numPoints-1) * Math.PI;
      if (animateOut) {
        this.delayPointsArray[i] = (Math.sin(-radian* range) + Math.sin(-radian * range) + 2) / 4 * this.delayPointsMax * Math.random();
      }else{
        this.delayPointsArray[i] = (1 - (Math.sin(-radian* range) + Math.sin(-radian * range) + 2) / 4) * this.delayPointsMax * Math.random();
      }
      // this.delayPointsArray[i] = 0;
      // this.delayPointsArray[i] = Math.random() * this.delayPointsMax;
    }
    if (animateOut === false) {
      this.open();
    } else {
      this.close();
    }
  }
  open() {
    this.isOpened = true;
    this.elm.current.classList.add('is-opened');
    this.timeStart = Date.now();
    this.renderLoop();
  }
  close() {
    this.isOpened = false;
    this.elm.current.classList.remove('is-opened');
    this.timeStart = Date.now();
    this.renderLoop();
  }
  updatePath(time) {
    const points = [];
    var i = 0;
    for (i = 0; i < this.numPoints; i++) {
      if (this.isOpened) {
        // points[i] = ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100
        points[i] = ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))
      }else{
        // points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
        points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)))
      }
    }

    // for (var i = 0; i < this.numPoints; i++) {
    //   const thisEase = this.isOpened ? 
    //                     (i == 1) ? ease.cubicOut : ease.cubicInOut:
    //                     (i == 1) ? ease.cubicInOut : ease.cubicOut;
    //   if (this.isOpened) {
    //     points[i] = thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100
    //   }else{
    //     points[i] = (1 - thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
    //   }
    // }

    let str = '';
    str += (this.isOpened) ? `M 0 0 V ${points[0]}` : `M 0 ${points[0]}`;
    for (i = 0; i < this.numPoints - 1; i++) {
        // const p = (i + 1) / (this.numPoints - 1) * 100;
        // const cp = p - (1 / (this.numPoints - 1) * 100) / 2;
        const p = (i + 1) / (this.numPoints - 1) ;
        const cp = p - (1 / (this.numPoints - 1) ) / 2;
      str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;  
    }
    str += (this.isOpened) ? `V 0 H 0` : `V 0 H 0`;
    // console.log(str)
    return str;
  }
  render() {
      var i = 0;
    if (this.isOpened) {
      for (i = 0; i < this.path.length; i++) {
        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * i)));
      }
    } else {
      for (i = 0; i < this.path.length; i++) {
        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * (this.path.length - i - 1))));
      }
    }
  }
  renderLoop() {
    this.render();
    if (Date.now() - this.timeStart < this.duration + this.delayPerPath * (this.path.length - 1) + this.delayPointsMax) {
      requestAnimationFrame(() => {
        this.renderLoop();
        // this.sectionElem.current.style.background="white";
        // this.sectionElem.current.style.background="transparent";
        // this.sectionElem.current.style.clipPath = "none";
        // this.sectionElem.current.offsetWidth; //force a style recalculation
        // this.sectionElem.current.style.clipPath = "url(#transition-clip)";
      });
    }
    else {
      this.isAnimating = false;
      // this.sectionElem.current.style.background="white";
      // this.sectionElem.current.style.background="transparent";
    }
  }
}

const TransitionElement = () => {
  // Prevent window scroll while mobile nav menu is open
  const { transitionWord, transitionWordFunny, animateOut, fromHeaderMenu } = useContext(TransitionElementContext);
//   const [backgroundClass, setBackgroundClass] = useState("background-grey");
  const isInitialMount = React.useRef(true);
//   useLockBodyScroll();
  const overlay = React.useRef();
  const shapeOverlays = React.useRef();
  const textWrapperRef = React.useRef();
  const timeline = React.useRef();
  const transitionRef = React.useRef();
  const timelineSafari = React.useRef();
//   const backgrounds = [
//     "background-green",
//     "background-green-light",
//     "background-yellow",
//     "background-blue",
//   ];


  useEffect(()=>{
    if(!isSafariBrowser() && !isEmbeddedBrowser()){
      overlay.current = new ShapeOverlays(shapeOverlays, transitionRef);
      timelineSafari.current = new gsap.timeline();
    }

    timeline.current = new gsap.timeline();
    timeline.current.set(textWrapperRef.current.querySelectorAll(".split-text-letter"), {y:"100%", opacity:1})
    // timeline.current.set(textWrapperRef.current.querySelectorAl(".split-text-letter"), {y:"100%", opacity:1})
    timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word .split-text-letter"), {y:0, stagger:0.04, duration: 1, ease:"Power4.easeOut"})
    timeline.current.to(textWrapperRef.current.querySelectorAll(".funny-word .split-text-letter"), {y:0, stagger:0.005, duration: 0.3, delay:-0.5, ease:"Power4.easeOut"})



    timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word .split-text-letter"), {y:"-100%", stagger:0, duration: 0.6, ease:"Expo.easeIn"})
    timeline.current.to(textWrapperRef.current.querySelectorAll(".funny-word .split-text-letter"), {y:"-100%", stagger:0, duration: 0.6, ease:"Expo.easeIn"}, "<")

    timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word .split-text-letter"), {y: 0, delay:0.5})
    timeline.current.set(textWrapperRef.current.querySelectorAll(".funny-word .split-text-letter"), {y:0, onComplete: () => {
      timeline.current.kill();
    }})

  },[])

  useEffect(()=>{
    // const t1 = gsap.timeline();
    // const start =  "M 0 0   V 0   Q 50  0   100 0   V 0 z";
    // const middle = "M 0 0   V 50  Q 50  100 100 50  V 0 z";
    // const end =    "M 0 0   V 100 Q 100 100 100 100 V 0 z";
    console.log("fromHeaderMenu", fromHeaderMenu, "animateOut", animateOut);
    if(!isSafariBrowser() && !isEmbeddedBrowser()){
      if(animateOut){
          overlay.current.toggle(animateOut);
      }else{
          if(isInitialMount.current){
              gsap.set("#transition-clip .shape-overlays__path", {
                  attr:{d:"M 0 0 V 1C 0.5 1 0.5 1 1 1 V 0 H 0"}
              })
              isInitialMount.current = false;
          }else{
              overlay.current.toggle(animateOut);
          }
      }
    }else{
      if(animateOut){
        gsap.set(transitionRef.current, {
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          // duration: 0.8
        })
        gsap.to(transitionRef.current, {
          clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)",
          delay: 0.1,
          duration: 0.8
        })
      }else{
        if(isInitialMount.current){
            gsap.set(transitionRef.current, {
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                // duration: 0.8
            })
            isInitialMount.current = false;
        }else{
          gsap.set(transitionRef.current, {
            clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)"
          })
          gsap.to(transitionRef.current, {
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            duration: 0.8,
            // delay: 0.1,
          })
        }
      }
    }
  }, [animateOut])

  return (
    <>
    <svg className="shape-overlays" viewBox="0 0 1 1" preserveAspectRatio="none" ref={shapeOverlays} >
        <defs>
            <clipPath clipPathUnits="objectBoundingBox" id="transition-clip">
                <path className="shape-overlays__path"></path>
            </clipPath>
        </defs>
    </svg>

    <div id="transition-element" className="flex flex-col justify-between px-5 xl:px-20" style={{position:"fixed", top:"0", zIndex:"999", background:"#191919", width:"100vw"}} ref={transitionRef}>
        <div style={{display: "flex", justifyContent:"space-between", alignItems:"center"}}>
            <h1 style={{fontSize: "30px", fontWeight:"500", color:"#191919" }}>Antecâmara</h1>
            <div id="nav-menu-button-loading" style={{ overflowX:"hidden"}}>
                <div style={{ width:"100%" }}>
                    <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 11" fill="white" style={{ width:"100%" }}>
                        <title>Asset 1</title>
                        <g id="Layer_2_1_">
                            <g id="Layer_1-2">
                                <rect width="87" height="11"></rect>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>

        <div className="px-5 md:px-20 mt-5 md:mt-0 text-white" style={{fontSize:"clamp(30px, 5vw, 70px)", lineHeight:"1.1", fontWeight:"500"}} ref={textWrapperRef}>
            <span style={{paddingRight:"2rem"}} className="transition-word"><SplitText text={transitionWord} role=""/></span> <span style={{fontSize:"clamp(14px, 1vw, 20px)", lineHeight:"1.2", display:"block"}} className="funny-word"><SplitText text={transitionWordFunny} role=""/></span>
        </div>
        <h1 style={{fontSize: "30px", fontWeight:"500", color:"white", alignSelf:"flex-end"}}></h1>
    </div>

    {/* <div style={{position:"fixed", top:"0", zIndex:"26", background:"white", height:"100vh", width:"100vw", pointerEvents:"none"}} ref={transitionRef}></div> */}
    </>
  );
};

export default TransitionElement;

import React, {useContext} from "react"
// import TransitionLink from 'gatsby-plugin-transition-link';
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { linkPropTypes } from "@/utils/types"
import { MouseContext } from "../../context/mouseContext";
import { TransitionElementContext } from "../../context/transitionElementContext";

const CustomLink = ({ link, className, hoverWord, children, noMouseEvents=false, fromHeaderMenu=false, transitionWord="", transitionWordFunny="" }) => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { pageTransitionElementHandler, isFromHeaderMenu } = useContext(TransitionElementContext);
  const isInternalLink = link.url.startsWith("/")


  const onMouseEnter = e => {
    if(!noMouseEvents){
      cursorChangeHandler("hovered", hoverWord);
    }
  }

  const onMouseLeave = e => {
    if(!noMouseEvents){
      cursorChangeHandler("", "");
    }
  }

  const onClickHandler = (url, word, wordFunny) => {
    cursorChangeHandler("","")
    isFromHeaderMenu(fromHeaderMenu);
    pageTransitionElementHandler(url, word, wordFunny);
  }
  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return <Link to={link.url}  className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={()=>{onClickHandler(link.url, transitionWord,transitionWordFunny)}}>{children}</Link>
  }

  // Plain <a> tags for external links
  return (
    <a
      className={className}
      href={link.url}
      // Change target and rel attributes is newTab is turned on
      target={link.newTab ? "_blank" : "_self"}
      rel="noreferrer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CustomLink


import React, {useContext} from 'react'
import useMousePosition from "../../hooks/useMousePosition";
import './customCursor.css';
import { MouseContext } from "../../context/mouseContext";
import gsap from "gsap";
// import { animation } from 'tailwindcss/defaultTheme';
import { isSafariBrowser, isFirefoxBrowser } from '../../utils/isBrowser';

const CustomCursor = () => {

  const { cursorType, cursorWord, playAnimation } = useContext(MouseContext);
  const { x, y } = useMousePosition();
  const [timeline, setTimeline] = React.useState(gsap.timeline())
  const dotRef = React.useRef();
  const wordRef = React.useRef();
  // const filterRef = React.useRef();
  // const turbulenceRef = React.useRef();
  // const displacementRef = React.useRef();
  const primitiveValues = React.useRef({scale: 0});
  const animationPlaying = React.useRef(false);

  React.useEffect(()=>{

    if(!isSafariBrowser()){
      if(!isFirefoxBrowser()){
        dotRef.current.style.backdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
        dotRef.current.style.webkitBackdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
        dotRef.current.style.background = "#000";
      }else{
        dotRef.current.style.background = "#fff"; 
      }
    }else{
      dotRef.current.style.background = "#fff";
      dotRef.current.style.backdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
      dotRef.current.style.webkitBackdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
      // dotRef.current.style.backdropFilter = "none";
      dotRef.current.style.transitionProperty = "width, height";
      dotRef.current.style.transitionDuration = "300ms, 300ms";
      dotRef.current.style.mixBlendMode = "none";


      wordRef.current.style.transitionProperty = "width, height";
      wordRef.current.style.transitionDuration = "300ms, 300ms";
    }
    setTimeline(gsap.timeline({
      paused: true,
      onStart: () => {
        // dotRef.current.style.filter = `url(#svg-filter)`;  
        animationPlaying.current = true;
      },

      onComplete: () => {
        // dotRef.current.style.filter = `sepia(5)
        //   /* saturate(100)  */
        //   invert(0)     
        //   grayscale(1)  
        //   contrast(1);`;
          animationPlaying.current = false;
      }
    }));
  }, []);

  React.useEffect(()=>{
    // console.log("performance check")
    if(!isSafariBrowser() && !isFirefoxBrowser()){
      console.log("not safari")
      if(playAnimation && !animationPlaying.current){
        primitiveValues.current = {scale: 0};
        timeline.set(dotRef.current, {filter: `url(#svg-filter)`})
        timeline.eventCallback('onUpdate', () => {
          
          document.getElementById("svg-filter-displacement").scale.baseVal = primitiveValues.current.scale;
          
        });
        // timeline.set(dotRef.current, {filter: `url(#svg-filter)`, webkitFilter: `url(#svg-filter)`})
        
        timeline.from(primitiveValues.current, { 
            duration: 1,
            ease: 'Expo.easeOut',
            scale: 180
        });

        // timeline.set(dotRef.current, {filter: `none`, clearProps:"filter"})
        timeline.play();
      }else {
        timeline.clear();
        timeline.set(dotRef.current, {
          filter:"none",
          clearProps:"filter"
        })
        
        animationPlaying.current = false;
        
      }

    }
  }, [playAnimation]);

  return (
    <>
            {/* 2. */}
      {/* <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={`custom-cursor-ring ${cursorType}`}
      ></div> */}
            {/* 3. */}

		  <svg className="hidden">
        <defs>
          <filter id="svg-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
            <feColorMatrix type="matrix" in="SourceGraphic" in2="displacement" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0" result="grayscale"/>
            <feTurbulence id="svg-filter-turbulence" type="fractalNoise" stitchTiles="stitch" baseFrequency="0.01 0.07" numOctaves="5" seed="2" result="noise"/>
            <feDisplacementMap id="svg-filter-displacement" in="grayscale" in2="noise" scale="0" x="0%" y="0%" width="50%" height="50%" xChannelSelector="R" yChannelSelector="B" result="displacement"/>
	          <feComposite in="displacement" in2="SourceGraphic" operator="in" x="0%" y="0%" width="150%" height="150%" result="composite3"/>
          </filter>
        </defs>
      </svg>
      <svg className="hidden">
        <defs>
          <filter id="color-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" result="colormatrix"/>
          </filter>
        </defs>
      </svg>
      <div
        ref={dotRef}
        className={`custom-cursor-dot ${cursorType}`}
        // style={{ left: `${x}px`, top: `${y}px` }}
        // style={{ WebkitTransform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)`, transform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)` }}
      ></div>

      <div
        ref={wordRef}
        className={`custom-cursor-word ${cursorWord ? "active" : ""}`}
        // style={{ left: `${x}px`, top: `${y}px` }}
        // style={{ WebkitTransform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)`, transform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)` }}
      >
          <span>{cursorWord}</span>
      </div>
    </>
  );
};

export default CustomCursor;
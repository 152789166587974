import React, { createContext, useState } from "react";
// import gsap from "gsap";

export const TransitionElementContext = createContext({
  transitionWord: "",
  transitionWordFunny: "",
  animateOut: false,
  fromHeaderMenu: false,
  pageTransitionElementHandler: () => {},
  openTransitionElementHandler: ( )=> {},
  closeTransitionElementHandler: ( )=> {},
});

const TransitionElementContextProvider = (props) => {
  const [transitionWord, setTransitionWord] = useState("We’re in the business");
  const [transitionWordFunny, setTransitionWordFunny] = useState("of risk-taking");
  const [animateOut, setAnimateOut] = useState(false);
  const [fromHeaderMenu, setFromHeaderMenu] = useState(false);

  const pageTransitionElementHandler = (url, transitionWord = "We've never learned much", transitionWordFunny = "from playing it safe") => {
    console.log(url, props.location)
    setTransitionWord(transitionWord ?? "Antecâmara Studio");
    setTransitionWordFunny(transitionWordFunny);
    // if(url != props.location){
    //   setAnimateOut(false);
    // }
  };

  const openTransitionElementHandler = () => {
    // console.log(fromHeaderMenu);
    setAnimateOut(false);
  };

  const closeTransitionElementHandler = () => {
    setAnimateOut(true);
  };

  const isFromHeaderMenu = (fromHeader = false) => {
    if(fromHeader != fromHeaderMenu){
      setFromHeaderMenu(fromHeader);
    }
  }

  return (
    <TransitionElementContext.Provider
      value={{
        transitionWord: transitionWord,
        transitionWordFunny: transitionWordFunny,
        animateOut: animateOut,
        fromHeaderMenu: fromHeaderMenu,
        pageTransitionElementHandler: pageTransitionElementHandler,
        openTransitionElementHandler: openTransitionElementHandler,
        isFromHeaderMenu: isFromHeaderMenu,
        closeTransitionElementHandler: closeTransitionElementHandler
      }}
    >
      {props.children}
    </TransitionElementContext.Provider>
  );
};

export default TransitionElementContextProvider;
function isSafariBrowser(){
    return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
}

function isFirefoxBrowser(){
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

function isEmbeddedBrowser(){
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
    var isFacebook = (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    return isInstagram || isFacebook;
}


module.exports = {
    isSafariBrowser,
    isFirefoxBrowser,
    isEmbeddedBrowser
}
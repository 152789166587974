import React from 'react'

const SplitText = ({text, role, noOverflow = false, noPositionRelative= false}) => {



    const buildLetters = () => {
        return text?.split(" ").map((word, index)=>{
            return ( <span aria-hidden="true" key={index} className="split-text-word p-0 m-0" 
                        style={{ display: "inline-block",
                                    overflow: noOverflow ? "visible" : "hidden",}}>
                {word?.split("").map(function(char, index){
                    return (
                            <span aria-hidden="true" key={index} className="split-text-letter" 
                            style={
                                {
                                    position: noPositionRelative ? "absolute" : "relative",
                                    display:noPositionRelative ? "default" : "inline-block",
                                }
                            }>
                                <span className="split-text-letter-inner">{char === " " ? (<>&nbsp;</>) : char}</span>
                            </span>
                    );
                })}
                <span className="split-text-letter-inner">&nbsp;</span>
            </span> );
        })
    }
    return (
        <span aria-label={text} className="split-text" aria-role={role} 
                style={
                    {
                        position: noPositionRelative ? "absolute" : "relative", 
                        display:noPositionRelative ? "default" : "inline-block", 
                        overflow: noOverflow ? "visible" : "hidden",
                        // whiteSpace: "nowrap",
                    }
                    }> 
                {buildLetters()}
        </span>
    )
}

export default SplitText;
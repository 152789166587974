import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import { MdClose, MdChevronRight } from "react-icons/md";
import Image from "../image";
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types";
import ButtonLink from "./button-link";
import { useLockBodyScroll } from "@/utils/hooks";
import { getButtonAppearance } from "@/utils/button";
import CustomLink from "./custom-link";
import gsap from "gsap"
import { isSafariBrowser, isEmbeddedBrowser } from "../../utils/isBrowser";


const blockScroll = () => {
  //prevent scrolling for loading
  // const bodyOriginalOverflow = document.body.style.overflow;
  // const bodyOriginalPaddingRight = document.body.style.paddingRight;

  let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;

  // Prevent scrolling on mount
  document.body.style.overflow = "hidden";
  document.body.style.position = "relative";
  // document.body.style.paddingRight = `${scrollbarWidthPx}px`;
  let padding = window.innerWidth >= 1600 ? "5rem" : "1.25rem";
  // console.log(window.innerWidth, scrollbarWidthPx, padding);
  // document.getElementById("transition-element").style.paddingRight= `calc(${padding} + ${scrollbarWidthPx}px)`;
  document.getElementById("main-wrapper").style.paddingRight = `${scrollbarWidthPx}px`;
  document.getElementById("navbar-antecamara").style.paddingRight = `${scrollbarWidthPx}px`;
  document.getElementById("navbar-menu-overlay").style.width = `calc(100vw + ${scrollbarWidthPx}px)`;
}

const unblockScroll = () => {
  // allow scrolling
  // let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";
  document.getElementById("main-wrapper").style.paddingRight = 0;
  document.getElementById("navbar-antecamara").style.paddingRight = 0;
}


const ease = {
  exponentialIn: (t) => {
    return t == 0.0 ? t : Math.pow(2.0, 10.0 * (t - 1.0));
  },
  exponentialOut: (t) => {
    return t == 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
  },
  exponentialInOut: (t) => {
    return t == 0.0 || t == 1.0
      ? t
      : t < 0.5
        ? +0.5 * Math.pow(2.0, (20.0 * t) - 10.0)
        : -0.5 * Math.pow(2.0, 10.0 - (t * 20.0)) + 1.0;
  },
  sineOut: (t) => {
    const HALF_PI = 1.5707963267948966;
    return Math.sin(t * HALF_PI);
  },
  circularInOut: (t) => {
    return t < 0.5
        ? 0.5 * (1.0 - Math.sqrt(1.0 - 4.0 * t * t))
        : 0.5 * (Math.sqrt((3.0 - 2.0 * t) * (2.0 * t - 1.0)) + 1.0);
  },
  cubicIn: (t) => {
    return t * t * t;
  },
  cubicOut: (t) => {
    const f = t - 1.0;
    return f * f * f + 1.0;
  },
  cubicInOut: (t) => {
    return t < 0.5
      ? 4.0 * t * t * t
      : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
  },
  quadraticOut: (t) => {
    return -t * (t - 2.0);
  },
  quarticOut: (t) => {
    return Math.pow(t - 1.0, 3.0) * (1.0 - t) + 1.0;
  },
}

class ShapeOverlays {
  constructor(elm) {
    this.elm = elm;
    this.path = elm.current.querySelectorAll('path');
    this.numPoints = 2;
    this.duration = 800;
    this.delayPointsArray = [];
    this.delayPointsMax = 300;
    this.delayPerPath = 30;
    this.timeStart = Date.now();
    this.isOpened = false;
    this.isAnimating = false;
  }
  toggle() {
    this.path = this.elm.current.querySelectorAll('path');
    this.isAnimating = true;
    const range = 4 * Math.random() + 6;
    for (var i = 0; i < this.numPoints; i++) {
      const radian = i / (this.numPoints-1) * Math.PI;
      if (this.isOpened) {
        this.delayPointsArray[i] = (Math.sin(-radian* range) + Math.sin(-radian * range) + 2) / 4 * this.delayPointsMax * Math.random();
      }else{
        this.delayPointsArray[i] = (1 - (Math.sin(-radian* range) + Math.sin(-radian * range) + 2) / 4) * this.delayPointsMax * Math.random();
      }
      // this.delayPointsArray[i] = 0;
      // this.delayPointsArray[i] = Math.random() * this.delayPointsMax;
    }
    if (this.isOpened === false) {
      this.open();
    } else {
      this.close();
    }
  }
  open() {
    this.isOpened = true;
    this.elm.current.classList.add('is-opened');
    this.timeStart = Date.now();
    this.renderLoop();
  }
  close() {
    this.isOpened = false;
    this.elm.current.classList.remove('is-opened');
    this.timeStart = Date.now();
    this.renderLoop();
  }
  updatePath(time) {
    const points = [];
    for (var i = 0; i < this.numPoints; i++) {
      if (this.isOpened) {
        // points[i] = ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100
        points[i] = ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))
      }else{
        // points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
        points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)))
      }
    }

    // for (var i = 0; i < this.numPoints; i++) {
    //   const thisEase = this.isOpened ? 
    //                     (i == 1) ? ease.cubicOut : ease.cubicInOut:
    //                     (i == 1) ? ease.cubicInOut : ease.cubicOut;
    //   if (this.isOpened) {
    //     points[i] = thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100
    //   }else{
    //     points[i] = (1 - thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
    //   }
    // }

    let str = '';
    str += (this.isOpened) ? `M 0 0 V ${points[0]}` : `M 0 ${points[0]}`;
    for (var i = 0; i < this.numPoints - 1; i++) {
      // const p = (i + 1) / (this.numPoints - 1) * 100;
      // const cp = p - (1 / (this.numPoints - 1) * 100) / 2;
      const p = (i + 1) / (this.numPoints - 1);
      const cp = p - (1 / (this.numPoints - 1)) / 2;
      str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;  
    }
    str += (this.isOpened) ? `V 0 H 0` : `V 0 H 0`;
    // console.log(str)
    return str;
  }
  render() {
    if (this.isOpened) {
      for (var i = 0; i < this.path.length; i++) {
        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * i)));
      }
    } else {
      for (var i = 0; i < this.path.length; i++) {
        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * (this.path.length - i - 1))));
      }
    }
  }
  renderLoop() {
    this.render();
    if (Date.now() - this.timeStart < this.duration + this.delayPerPath * (this.path.length - 1) + this.delayPointsMax) {
      requestAnimationFrame(() => {
        this.renderLoop();
      });
    }
    else {
      this.isAnimating = false;
    }
  }
}

const MobileNavMenu = ({ navbar, animateOut, closeMenu, toggleMenu, delay = false }) => {
  // Prevent window scroll while mobile nav menu is open
  const [backgroundClass, setBackgroundClass] = useState("background-grey");
  // useLockBodyScroll();
  const overlay = React.useRef();
  const overlayElementRef = React.useRef();
  const shapeOverlays = React.useRef();
  const menuWrapperTimeline = React.useRef();
  const backgrounds = [
    "background-green",
    "background-green-light",
    "background-yellow",
    "background-blue",
  ];


  useEffect(()=>{

    if(!isSafariBrowser() && !isEmbeddedBrowser()){
      overlay.current = new ShapeOverlays(shapeOverlays);
    }else{
      menuWrapperTimeline.current = gsap.timeline();
    }

  },[])

  useEffect(()=>{
    const t1 = gsap.timeline();
    // if(!menuWrapperTimeline.current){
    //   menuWrapperTimeline.current = gsap.timeline();
    // }
    const start =  "M 0 0   V 0   Q 50  0   100 0   V 0 z";
    const middle = "M 0 0   V 50  Q 50  100 100 50  V 0 z";
    const end =    "M 0 0   V 100 Q 100 100 100 100 V 0 z";
    if(animateOut){
      
      gsap.to("#navbar-menu-overlay",{duration: delay ? 0.3 : 0 , onComplete:()=>{
        if(!isSafariBrowser() && !isEmbeddedBrowser()){
          overlay.current.toggle();
        }else{
          // menuWrapperTimeline.current.set(overlayElementRef.current, {
          //   clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          //   // duration: 0.8
          // })
          menuWrapperTimeline.current.fromTo(overlayElementRef.current, {
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          },
          {
            clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)",
            duration: 0.8
          })
          // menuWrapperTimeline.current.set(overlayElementRef.current, {
          //   clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
          //   // delay: 0.8,
          //   // duration: 0.8
          // })
          
        }
        unblockScroll();
      }})
      // t1.set(".shape-overlays__path", {attr: { d: start }, ease: "Cubic.easeOut"});
      // t1.to(".shape-overlays__path", 0.4, {attr: { d: middle }, ease: "Cubic.easeIn"});
      // t1.to(".shape-overlays__path", 0.8, {attr: { d: start }, ease: "Cubic.easeOut"});
      // t1.set("#navbar-menu-overlay", {height:"100%"});
      // t1.set("#navbar-menu-overlay", {background:"transparent"});
      t1.set(".navbar-menu-overlay-items", {autoAlpha:1});
      t1.to(".navbar-footer", {autoAlpha:0, duration:0.2});
      t1.to(".navbar-menu-overlay-items:not(.clicked)", {autoAlpha:0, duration:0.3, delay: -0.2, ease: "Power2.easeIn"});  
      // t1.to("#navbar-menu-overlay", {height:0, duration:0.5, delay: 1});
      t1.to("#navbar-menu-overlay", {height:"100%", duration: delay ? 0.8 : 0.5, delay: 0});
      t1.eventCallback("onComplete",() => {
        closeMenu();
      });
    }else{
      blockScroll()
      if(!isSafariBrowser() && !isEmbeddedBrowser()){
        overlay.current.toggle();
      }else{
        // menuWrapperTimeline.current.set(overlayElementRef.current, {
        //   clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
        //   // duration: 0.8
        // })
        menuWrapperTimeline.current.fromTo(overlayElementRef.current, {
          clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)",
        },
        {
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          duration: 0.8
        })
        // menuWrapperTimeline.current.set(overlayElementRef.current, {
        //   clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        //   // delay: 0.8,
        //   // duration: 0.8
        // })
      }
      // t1.set(".shape-overlays__path", {attr: { d: start }, ease: "Cubic.easeOut"});
      // t1.to(".shape-overlays__path", 0.8, {attr: { d: middle }, ease: "Cubic.easeIn"});
      // t1.to(".shape-overlays__path", 0.4, {attr: { d: end }, ease: "Cubic.easeOut"});
      t1.set("#navbar-menu-overlay", {height:"100vh"});
      // t1.set("#navbar-menu-overlay", {background:"transparent"});
      t1.set(".navbar-menu-overlay-items", {autoAlpha:0});
      t1.set(".navbar-footer", {autoAlpha:0});
      // t1.to("#navbar-menu-overlay", {height:"100vh", duration:0.5});
      t1.to(".navbar-menu-overlay-items", {autoAlpha:1, stagger: 0.05, duration:0.5, delay: 0.2});
      t1.to(".navbar-footer", {autoAlpha:1, duration:0.5, delay: -0.4});
    }
  }, [animateOut])

  return (
    <>
    {/* <svg className="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none" ref={shapeOverlays}>
      <path className="shape-overlays__path"></path>
    </svg> */}
    <svg className="shape-overlays" viewBox="0 0 1 1" preserveAspectRatio="none" ref={shapeOverlays} >
        <defs>
            <clipPath clipPathUnits="objectBoundingBox" id="nav-menu-clip">
                <path className="shape-overlays__path"></path>
            </clipPath>
        </defs>
    </svg>
    <div id="navbar-menu-overlay" className={`w-screen h-0 fixed top-0 left-0 overflow-y-scroll bg-white background-change ${backgroundClass}`} style={{zIndex:"10" , background:"#191919", clipPath: isSafariBrowser() || isEmbeddedBrowser() ? "" : "url(#nav-menu-clip)"}} ref={overlayElementRef}>
      <div className="px-5 md:px-20 pt-20 h-full flex flex-col justify-center">
        {/* Bottom section */}
        <div className="flex flex-col justify-start my-auto">
          <ul className="navbar-menu-overlay-items-wrapper flex flex-col list-none gap-6 w-full items-baseline mb-10">
            {navbar.links.map((navLink, index) => (
              <li key={navLink.id} className="navbar-menu-overlay-items block w-full mt-10" 
                  onMouseEnter={() => setBackgroundClass(backgrounds[index])} 
                  onMouseLeave={() => setBackgroundClass("background-grey")} 
                  onClick={(e) => {
                    e.target.closest(".navbar-menu-overlay-items").classList.add("clicked");
                    toggleMenu()
                  }} >
                <CustomLink link={navLink} fromHeaderMenu={true}>
                  <div className="navbar-menu-overlay-items-text-wrapper mt-5 md:mt-0" style={{fontSize:"clamp(30px, 5vw, 70px)", fontWeight:"500"}}>
                    <span style={{paddingRight:"2rem", alignSelf:"end"}}>{navLink.text}</span> <span className="funny-phrase" style={{fontSize:"clamp(14px, 1vw, 20px)", display:"block", alignSelf:"end"}}>{navLink.transitionWordFunny}</span>
                  </div>
                </CustomLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between justify-self-end align-self-end pb-10 pr-5 text-white navbar-footer">
          <div className="flex items-center">
              <div className="p-2"><div className="circle circle-white circle-small"></div></div>
              <a href="mailto:geral@antecamarastudio.com" className="text-white" style={{fontSize:"clamp(14px, 3vw,20px)", fontWeight:"400"}}>geral@antecamarastudio.com</a>
          </div>
          <div className="flex items-center">
            {/* <p style={{fontSize:"20px", fontWeight:"400"}}>pt / en</p> */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  closeSelf: PropTypes.func,
};

export default MobileNavMenu;

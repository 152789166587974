import React, {useEffect, useRef} from "react"
import PropTypes from "prop-types"
import { mediaPropTypes } from "@/utils/types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getStrapiMedia } from "../utils/media"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Image = ({ media, className, style, transitionType="fade" }) => {
  const isDynamicImage = Boolean(media.localFile)
  const alt = media.alternativeText || "An image uploaded to Strapi"
  const imageRef = React.useRef();
  const timeline1 = useRef();

  useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger);
    timeline1.current = gsap.timeline({scrollTrigger: {
      trigger: imageRef.current,
      id:"imageScrollTrigger-id",
      // scrub: 1,
      start: "top 90%",
      // end: "top center",
      // markers:true,
      once:true,
    }});
    // Now we are going to animate
    if(transitionType === "fade"){
      timeline1.current.fromTo(
        imageRef.current,
        {
          // clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
          autoAlpha: 0,
          y:20,
          scale: 1,
        },
        {
          // clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          scale: 1,
          y:0,
          autoAlpha:1,
          // delay: 0.3 * Math.random(),
          duration:0.8,
          ease: "Expo.easeOut",
          //  We want to do that animation on scroll
          
        }
      );
    }

    return () => {
      // if(ScrollTrigger.getById("imageScrollTrigger-id")){
      //   ScrollTrigger.getById("imageScrollTrigger-id").kill();
      // }
      if(timeline1.current){
        timeline1.current.kill()
      }
    }
  }, [])

  if (isDynamicImage) {
    return (
      // <GatsbyImage
      //   className={className}
      //   style={style}
      //   objectFit="cover"
      //   image={getImage(media.localFile)}
      //   alt={alt}
      //   loading={"eager"}
      //   placeholder={"dominant_color"}
      // />
      <img
        ref={imageRef}
        className={className}
        placeholder="blurred"
        style={style}
        src={media.localFile.publicURL}
        alt={alt}
        loading="eager"
        draggable="false"
      />
    )
  }

  return (
    <img
      ref={imageRef}
      src={getStrapiMedia(media.url)}
      alt={alt}
      style={style}
      className={className}
      loading="eager"
      draggable="false"
    />
  )
}

Image.propTypes = {
  media: mediaPropTypes,
  className: PropTypes.string,
}

export default Image

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import React from "react"
import { CookiesProvider } from "react-cookie"
// import { TransitionGroup, Transition } from "react-transition-group"

import CustomCursor from "./src/components/elements/customCursor"
import MouseContextProvider from "./src/context/mouseContext";
import TransitionElementContextProvider from "./src/context/transitionElementContext";
// import gsap from "gsap";
import PageTransition from "./src/components/page-transition"
import TransitionElement from "./src/components/transition-element"
import Navbar from "./src/components/elements/navbar"
// import Footer from "./src/components/elements/footer"


import ReactDOM from 'react-dom'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}



export const wrapRootElement = ({ element }) => {
  return (
    <CookiesProvider>
      {element}
    </CookiesProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // console.log(props)
  // const { navbar, footer, notificationBanner } = props.data.strapiGlobal
  const { navbar } = props.data.strapiGlobal

  return (
    <TransitionElementContextProvider location={props.path}>
      <TransitionElement/>
      <MouseContextProvider>
        
          <CustomCursor/>
          <Navbar navbar={navbar} pageContext={props.pageContext} location={props.path}/>
          <PageTransition path={props.path}>
            {element}
          </PageTransition>
      </MouseContextProvider>
    </TransitionElementContextProvider>
  )
}
import React, {useEffect, useContext} from "react"
import { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { MdMenu } from "react-icons/md"
import MobileNavMenu from "./mobile-nav-menu"
import ButtonLink from "./button-link"
import gsap from "gsap"
import { MouseContext } from "../../context/mouseContext";
import { TransitionElementContext } from "../../context/transitionElementContext";
// import Image from "../image"
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "@/utils/types"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "./custom-link"
import LocaleSwitch from "../locale-switch"
import { localizePath } from "@/utils/localize"

const Navbar = ({ navbar, pageContext, location }) => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { fromHeaderMenu, isFromHeaderMenu, closeTransitionElementHandler } = useContext(TransitionElementContext);
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const [animateOut, setAnimateOut] = useState(false);
  const [delayOut, setDelayOut] = useState(false);
  // const shouldAnimate = React.useRef(true);


  const toggleMenu = () =>{
    // console.log("toggle Menu")
    // console.log(animateOut, mobileMenuIsShown);
    if(delayOut){
      setDelayOut(false);
    }
    if(mobileMenuIsShown){
      setAnimateOut(true);
      // setMobileMenuIsShown(false);
    }else{
      setAnimateOut(false);
      setMobileMenuIsShown(true);
    }
  }

  const onMouseEnter = e => {
    cursorChangeHandler("hovered", "");
  }

  const onMouseLeave = e => {
    cursorChangeHandler("", "");
  }

  useEffect(()=>{
    const t1 = gsap.timeline(); 
    t1.set("#navbar-antecamara", {autoAlpha: 0})
    t1.to("#navbar-antecamara", {autoAlpha: 1, delay:0.5, duration:1});
  },[])

  /** TODO hide and show navbar on scroll, change background from transparent to color  */
  return (
    <>
      {/* The actual navbar */}
      <nav id="navbar-antecamara" className={`pt-5 md:pt-10 ${mobileMenuIsShown ? "menu-open" : ""}`} style={{position:"fixed", top:"0",left:"0", width:"100%", mixBlendMode:"difference", zIndex:"30"}}>
        <div className="px-5 xl:px-20 navbar-wrapper">
          {/* Content aligned to the left */}
          <div className="flex flex-row items-center">
            <CustomLink
              link={{url: localizePath({ ...pageContext, isPreview: false, slug: "" })}}
              transitionWord="Antecâmara Studio"
              transitionWordFunny="Make more mistakes, just like our parents did."
            >
              {/* <Image
                placeholder="none"
                style={{ width: "112px" }}
                media={navbar.logo}
                className="h-8 w-auto object-contain"
              /> */}
              <h1 className="text-white" style={{fontSize:"30px", fontWeight:"500"}}>Antecâmara</h1>
            </CustomLink>
          </div>
          <div className="flex items-center h-full">

            {/* List of links on desktop */}
            <ul className={`h-full navbar-links hidden list-none lg:flex flex-row items-center ${mobileMenuIsShown ? 'lg:hidden' : ''}`} style={{fontSize:"20px", fontWeight:"400"}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              {navbar.links.map((navLink, index) => (
                <li className="h-full navbar-link flex items-center" key={navLink.id} style={{fontSize:"20px", fontWeight:"400"}}>
                  <CustomLink
                    link={{
                      ...navLink,
                      url: `${localizePath({
                        ...pageContext,
                        isPreview: false,
                        // Remove the '/'
                        slug: navLink.url.slice(1),
                      })}`,
                    }}
                    transitionWord={navLink.transitionWord}
                    transitionWordFunny={navLink.transitionWordFunny}
                    noMouseEvents={true}
                  >
                    <div className="text-white h-full inline-block antecamara-nav-link" >
                      {index == 0 ? ( <span style={{fontWeight: location == navLink.url ? "700" : "400"}}>{navLink.text}</span> ) : ( <><span>, </span> <span style={{fontWeight: location == navLink.url ? "700" : "400"}}>{navLink.text}</span></>)}
                    </div>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center justify-self-end">
            
            <div className="flex items-center py-3" id="nav-menu-button-wrapper" style={{height:"100%"}} onClick={toggleMenu} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <svg version="1.1" id="nav-menu-button" xmlns="http://www.w3.org/2000/svg" x="87px" y="11px" viewBox="0 0 87 11">
                  <title>Asset 1</title>
                  <g id="Layer_2_1_">
                      <g id="Layer_1-2">
                          <rect width="87" height="11"></rect>
                      </g>
                  </g>
              </svg>
              <svg version="1.1" id="nav-menu-button-close" xmlns="http://www.w3.org/2000/svg" x="87px" y="11px" viewBox="0 0 87 11">
                  <title>Asset 1</title>
                  <g id="Layer_2_1_">
                      <g id="Layer_1-2">
                          <rect width="87" height="11"></rect>
                      </g>
                  </g>
              </svg>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile navigation menu panel */}
      {mobileMenuIsShown && (
        <MobileNavMenu
          navbar={navbar}
          animateOut={animateOut}
          closeMenu={()=>{
            setMobileMenuIsShown(false);
          }}
          delay={delayOut}
          toggleMenu={(url) => {
            // console.log("exit menu")
            if(!delayOut){
              setDelayOut(true);
            }
            setAnimateOut(true);
            closeTransitionElementHandler();
          }}
        />
      )}
    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
}

export default Navbar



// {/* Locale Switch Mobile */}
// {pageContext.localizations && (
//   <div className="md:hidden">
//     <LocaleSwitch pageContext={pageContext} />
//   </div>
// )}
// {/* Hamburger menu on mobile */}
// <button
//   onClick={() => setMobileMenuIsShown(true)}
//   className="p-1 block md:hidden"
// >
//   <MdMenu className="h-8 w-auto" />
// </button>

// {/* CTA button on desktop */}
// {navbar.button && (
//   <div className="hidden md:block">
//     <ButtonLink
//       button={navbar.button}
//       appearance={getButtonAppearance(navbar.button.type, "white")}
//       compact
//     />
//   </div>
// )}
// {/* Locale Switch Desktop */}
// {pageContext.localizations && (
//   <div className="hidden md:block text-shite">
//     <LocaleSwitch pageContext={pageContext} />
//   </div>
// )}
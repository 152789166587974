import React, { createContext, useRef, useContext } from "react"
import { TransitionGroup, Transition } from "react-transition-group"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TransitionElementContext } from "../context/transitionElementContext";


const blockScroll = (fromHeaderMenu = false) => {
    //prevent scrolling for loading
    // const bodyOriginalOverflow = document.body.style.overflow;
    // const bodyOriginalPaddingRight = document.body.style.paddingRight;

    let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;

    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    document.body.style.position = "relative";
    document.body.style.paddingRight = `${scrollbarWidthPx}px`;
    let padding = window.innerWidth >= 1600 ? "5rem" : "1.25rem";
    // console.log(window.innerWidth, scrollbarWidthPx, padding);
    document.getElementById("transition-element").style.paddingRight= `calc(${padding} + ${scrollbarWidthPx}px)`;
    document.getElementById("navbar-antecamara").style.paddingRight = `${scrollbarWidthPx}px`;
}

const unblockScroll = () => {
    // allow scrolling
    // let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
    document.getElementById("main-wrapper").style.paddingRight = 0;
    document.getElementById("navbar-antecamara").style.paddingRight = 0;
}


export const PageTransitionContext = createContext({
    timelineLoading: null,
    timelineEnter: null,
    timelineExit: null,
    isLoaded: null,
    addToTimeline: () => {},
});

const completeCall = (target, parent = null) => {
	target &&
		gsap.set(target, {
			clearProps: "position, width, transform, top, left, visibility, overflow, opacity",
            // onComplete:() => {
            //     ScrollTrigger.refresh()
            // }
		});
	parent &&
		gsap.set(parent, {
			clearProps: "transform, overflow, position, min-height",
		});
	target && gsap.set(target, { clearProps: "transform, overflow" });

    target.style = "";
	// let anchor = getAnchor();
	// if (anchor) {
	// 	var anchor1 = document.getElementById(anchor);
	// 	var posTop = anchor1.offsetTop;

	// 	gsap.to(window, 1, { scrollTo: posTop });
	// }
};

const PageTransition = ({ children, path }) => {
    const { closeTransitionElementHandler, openTransitionElementHandler, fromHeaderMenu, isFromHeaderMenu } = useContext(TransitionElementContext);
    const timelineLoading = useRef();
    const timelineEnter = useRef();
    const timelineExit = useRef();
    const isLoadedRef = useRef(false);
    const [pageRefresh, setPageRefresh] = React.useState(0);

    React.useEffect(()=>{
        // console.log("loading")

        blockScroll();
        timelineLoading.current = gsap.timeline(); 
        timelineEnter.current = gsap.timeline();
        timelineExit.current = gsap.timeline();
        timelineLoading.current.set("#transition-element",{
            // position:"absolute",
            autoAlpha:1
        })

        timelineLoading.current.set("#main-wrapper",{
            // position:"absolute",
            autoAlpha:0
        })

        timelineLoading.current.set("#transition-element #nav-menu-button-loading",{
            // position:"absolute",
            // width:0
            width:"min(15vw, 70px)",
            clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
        })
        timelineLoading.current.to("#transition-element #nav-menu-button-loading",{
            // position:"absolute",
            width:"min(15vw, 70px)",
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            duration:1.5,
        })
         
        // gsap.set("body", {background:"black"})
        timelineLoading.current.set("#main-wrapper",{
            // position:"absolute",
            autoAlpha:1
        })
        timelineLoading.current.to("#transition-element",{
            // position:"absolute",
            autoAlpha:1,
            duration: 0.3,
            onComplete:()=>{
                isLoadedRef.current = true;
                closeTransitionElementHandler();
                unblockScroll(fromHeaderMenu);
                window.scrollTo(0,0);
                setPageRefresh(pageRefresh + 1);
                ScrollTrigger.clearScrollMemory();
                ScrollTrigger.getAll().forEach((element)=>{
                    element.disable(true,true);
                })
                // window.scrollTo(0,0);
                ScrollTrigger.getAll().forEach((element)=>{
                    element.enable();
                })
                ScrollTrigger.refresh();
                
                // ScrollTrigger.refresh();
                // ScrollTrigger.update();

                // window.scrollTo(900,900);



                
            }
        })
        // console.log("loading added")
        // timeline.add(timelineLoading);

        // setTimelineLoading(timeline);

        return () => {
            if(timelineLoading){
                timelineLoading.current.kill()
            }
            if(timelineEnter){
                timelineEnter.current.kill()
            }
            if(timelineExit){
                timelineExit.current.kill()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const addToTimeline = (animation) => {
        // console.log("adding other animation")
        if(isLoadedRef.current){
            timelineEnter.current.add(animation, "-=1");
        }else{
            timelineLoading.current.add(animation);
        }
    }

    const onEnterHandler = (node) => {
        timelineEnter.current.killTweensOf(node);

        // Set initial position and styles
        // gsap.set(node, {
        // 	// position: "absolute",
        // 	// left: 0,
        // 	// top: 0,
        // 	autoAlpha: 0,
        // });

        // gsap.to(node, {
        // 	duration: 0,
        // 	autoAlpha: 1,
        // 	// x: 0,
        // 	// onComplete: completeCall,
        // 	// onCompleteParams: [node, parentNode.current],
        // });
        

        // node.style.position = "absolute";
        // node.style.top = 0 + ( -1 * window.scrollY ) + "px";

        timelineEnter.current.set(node,{
            position:"absolute",
            // top : 0,
            overflow: "hidden",
            // left: 0,
            // zIndex : -1,
            // position:"absolute",
            // position:"initial",
            // marginBottom:"-100%",
            autoAlpha:0,
            // delay: 0.8
        })
        
        timelineEnter.current.to(node,{
            duration: 0,
            delay: fromHeaderMenu ? 0 : 1.1,
            autoAlpha: 1,
            ease: "Power.out",
            onComplete: (node) => {
                completeCall(node);
                closeTransitionElementHandler();
                unblockScroll();
                window.scrollTo(0,0);
                setPageRefresh(pageRefresh + 1)
                // isFromHeaderMenu(false);
            },
            onCompleteParams: [node],
        })
    };
  
    const onExitHandler = (node) => {
        // window.history.scrollRestoration = "manual"
        console.log("trigger transition");
        if(!fromHeaderMenu){
            openTransitionElementHandler();
        }
        ScrollTrigger.clearScrollMemory();
        ScrollTrigger.getAll().forEach((element)=>{
            element.disable(false,true);
        })

        let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;
        node.querySelector("#main-wrapper").style.paddingRight = scrollbarWidthPx + 'px';
        // console.log(scrollbarWidthPx)
        node.style.position = "absolute";
        node.style.top = -1 * window.scrollY + "px";
        // ScrollTrigger.getAll().forEach((element)=>{
        //     // element.clearScrollMemory();
        //     element.kill()
        // });
        blockScroll(fromHeaderMenu);
        // node.style.position = "fixed";
        // node.style.top = -1 * window.scrollY + "px";
        // console.log(node)
        // console.log("exit");
        // gsap.set(node, {
            // 	// position: "absolute",
            // 	// left: 0,
            // 	// zIndex: -2,
        //   autoAlpha: 1,
            // });
        //   const timeline1 = gsap.timeline()
        // ScrollTrigger.getAll().forEach(t => t.kill());
        // timelineExit.current.to("#transition-element" ,{
        //     // x: "100vw",
        //     autoAlpha:0,
        //     duration: 0.1,
        // })

        // node.style.position = "fixed";
        // node.style.top = -1 * window.scrollY + "px";
        // scrollbarWidthPx = 0;

        // timelineExit.current.set(node, {
        //     // position:"absolute",
        //     // top: `calc(${-1 * window.scrollY}px)`,
        //     // zIndex:1,
        // })

        // timelineExit.current.set(node.querySelector('#main-wrapper'), {
        //     paddingRight: scrollbarWidthPx + "px"
        // })
        // console.log(-1 * window.scrollY);
        // timelineExit.current.set(node, {
        //     // position:"absolute",
        //     // top: `calc(${-1 * window.scrollY}px)`,
        //     // delay: 0.8
        // })
        // timelineExit.current.set(node, {
        //     // paddingBottom:"100vh",
        //     // left:0,
        // })
        
        

        timelineExit.current.to(node, {
            autoAlpha: 0,
            duration: 0,
            delay:fromHeaderMenu ? 0 : 1.1,
        })
        // timelineExit.current.to("#transition-element",{
        //     // position:"absolute",
        //     autoAlpha:0,
        //     duration: 0,
        //     onComplete:()=>{
        //         // isLoadedRef.current = true;
        //     }
        // })


        // gsap.to(node, {
        // 	duration: 0,
        // 	// delay: 0.4,
        // 	autoAlpha: 0,
        // });
  
        // gsap.killTweensOf(node);
    };
    
    return (
        <TransitionGroup component={null}>
            <Transition
                timeout={2000}
                key={path}
                onEnter={onEnterHandler}
                onExit={onExitHandler}
                onEntered={
                    () => {
                        gsap.set("body", {
                            delay: 0.5,
                            onComplete: () => {
                                ScrollTrigger.refresh()
                            }
                        })
                    }
                }
            >
                <PageTransitionContext.Provider
                    value={{
                        timelineLoading: timelineLoading.current,
                        timelineEnter: timelineEnter.current,
                        timelineExit: timelineExit.current,
                        isLoaded: isLoadedRef.current,
                        addToTimeline: addToTimeline,
                    }}
                >
                    <div key={pageRefresh} className="w-full">
                        {children}
                    </div>
                </PageTransitionContext.Provider>
            </Transition>
        </TransitionGroup>
    )
  }



export default PageTransition
import React, { createContext, useState } from "react";
import gsap from "gsap";

export const MouseContext = createContext({
  cursorType: "",
  cursorWord: "",
  playAnimation: false,
  cursorChangeHandler: () => {},
});

const MouseContextProvider = (props) => {
  const [cursorType, setCursorType] = useState("");
  const [cursorWord, setCursorWord] = useState("");
  const [playAnimation, setPlayAnimation] = useState(false);

  const cursorChangeHandler = (cursorType, cursorWord = "") => {
    setCursorType(cursorType);
    setCursorWord(cursorWord);
    if(cursorType == "hovered"){
      setPlayAnimation(true);
    }else{
      setPlayAnimation(false);
    }
  };

  return (
    <MouseContext.Provider
      value={{
        cursorType: cursorType,
        cursorWord: cursorWord,
        playAnimation: playAnimation,
        cursorChangeHandler: cursorChangeHandler,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};

export default MouseContextProvider;